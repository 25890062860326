.Passcode {
    position: relative;
    &-chars {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &-char {
        & {
            position: relative;
            display: block;
            width: 58px;
            height: 58px;
            border: 1px solid #CDCDCD;
            border-radius: 6px;
            background: #F2F2F2;
            box-shadow: 0px 2px 1px -1px #FFFFFF;
        }
        & + & {
            margin-left: 15px;
        }
        &--filled:after {
            $size: 17px;
            content: '';
            position: absolute;
            width: $size;
            height: $size;
            top: 50%;
            left: 50%;
            margin-top: -0.5 * $size;
            margin-left: -0.5 * $size;
            border-radius: 0.5 * $size;
            background: #444444;
        }
    }
    input {
        opacity: 0;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: red;
        z-index: 1;
        // reset
        appearance: none;
        border: 0px;
        background: transparent;
        box-shadow: none;
        outline: none;
        border-radius: 0px;
    }
}