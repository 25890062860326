.DateOfBirth {

    .date-component {
        position: relative;
    }
    .date-component + .date-component:after {
        content: '/';
        font-size: 17px;
        color: #909090;
        position: absolute;
        top: 50%;
        margin-top: -14px;
        left: 0%;
        margin-left: 8px;
    }
    
    .date-component input {
        appearance: none;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0px;
        border-bottom: 1px solid black;
        text-align: center;
        padding-bottom: 8px;
        width: 100%;
    }
}