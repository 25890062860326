.MenuButtonIconWrapper {
    float: left;
    width: 78px;
    text-align: center;
    height: 100%;
}

.MenuButtonIconWrapper > img {
    margin: 22px auto auto auto;
}

.MenuButtonTitleWrapper {
    text-align: left;
    color: #333b44;
    margin-left: 78px;
    margin-right: 48px;
}

.MenuButtonTitleWrapper > * {
    margin: 0;
}

.MenuButtonTitleWrapper :first-child {
    font-size: 18px;
    letter-spacing: 0.4px;
    margin-top: 21px;
    margin-bottom: 4px;
}

.MenuButtonTitleWrapper:last-child {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
}

.MenuButtonChevronWrapper {
    float: right;
    width: 48px;
}

.MenuButtonChevronWrapper > img {
    margin-top: 27px;
    text-align: center;
}