#AddDebitCardPage label {
    color: #30363f;
}
#AddDebitCardPage .debit-card-logo {
    width: 20px;
    height: 18px;
    object-fit: contain;
}
#AddDebitCardPage .input-group-text {
    background: #F4F4F4;
}
#AddDebitCardPage .field-error-message {
    color: #db002b;
    line-height: 1;
    font-size: 14px;
    margin-top: 8px;
}
#AddDebitCardPage ::placeholder {
    color: rgba(58, 58, 58, 0.82);
    font-family: Arial;
    font-weight: 400;
    font-size: 16px;
    text-rendering: auto;
    opacity: 0.82;
    /* -webkit-font-smoothing: none; */
}

/* Safari only ghetto code from here https://stackoverflow.com/a/25975282/8106110 */
@media not all and (min-resolution:.001dpcm) { @media {
    ::-webkit-input-placeholder {
        color: rgba(58, 58, 58, 0.47);
        font-family: Arial;
        font-weight: 400;
        font-size: 16px;
        text-rendering: auto;
        opacity: 0.47!important;
        /* -webkit-font-smoothing: none; */
    }
}}

#AddDebitCardPage ::-webkit-input-placeholder {
    color: rgba(58, 58, 58, 0.82);
    font-family: Arial;
    font-weight: 400;
    font-size: 16px;
    text-rendering: auto;
    opacity: 0.82;
    /* -webkit-font-smoothing: none; */
}
#AddDebitCardPage :-ms-input-placeholder {
    color: rgba(58, 58, 58, 0.82);
    font-family: Arial;
    font-weight: 400;
    font-size: 16px;
    text-rendering: auto;
    opacity: 0.82;
    /* -webkit-font-smoothing: none; */
}
#AddDebitCardPage .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
