$validated-address-border-color: #1da3ce50 !default;
$validated-address-background: #1da3ce10 !default;
$validated-address-background-active: #1da3ce25 !default;

.ValidatedAddresses {
    &, * {
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
    }
    margin-top: 25px !important;
    &-items {
        list-style-type: none;
    }
    &-item {
        border-bottom: 1px solid #B1B9BB80;
        &:first-child {
            border-top: 1px solid #B1B9BB80;
        }
    }
    &-cta {
        position: relative;
        appearance: none;
        border: 0px;
        background: $validated-address-background;
        color: #1da3ce;
        border-radius: 0px;
        width: 100%;
        padding: 16px 36px 12px 16px;
        font-size: 14px;
        text-align: left;
        &:active {
            background-color: $validated-address-background-active;
        }
        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            width: 8px;
            height: 8px;
            box-shadow: 2px -2px 5px -3px white;
            border-top: 2px solid #1da3ce;
            border-right: 2px solid #1da3ce;
            transform: translateX(-25%) translateY(-50%) rotate(45deg);
        }
    }
    &-item--error &-cta {
        color: #C81E3A;
        background-color: #C81E3A15;
        &:active {
            background-color: #C81E3A25;
        }
        &:after {
            border-color: #C81E3A;
        }
    }
}