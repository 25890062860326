.IconedTitle {
    height: auto;
    display: flex;
    align-items: top;
    justify-content: space-between;
}

.IconedTitleIconWrapper {
    float: left;
    margin-top: 8px;
}

.IconedTitleTitleWrapper {
    text-align: left;
    margin-left: 12px;
}

.IconedTitleTitleWrapper > p:first-child {
    line-height: 1.35;
}

.IconedTitleTitleWrapper > p + p {
    line-height: 1.4;
}

.IconedTitleTitleWrapper > * {
    margin: 0;
}

.IconedTitle p:nth-child(1) {
    -webkit-font-smoothing: subpixel-antialiased;
    color: #333b44
}
.IconedTitle p:nth-child(2) {
    color: #909090
}

.IconedTitleTitleWrapper :first-child {
    font-size: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 4px;
}

.IconedTitleTitleWrapper:last-child {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
}

.LockWrapper {
    margin-left: 8px;
}