.CardDesign {
    display: block;
    width: calc(50% - 7px);
    margin-bottom: 7px;
    position: relative;
}
.CardDesign .card-visual {
    box-shadow: 0px 7px 5px -3px #00000035;
    transform: scale(0);
    opacity: 0;
    transition: 0.8s transform cubic-bezier(0.3, 1.3, 0.7, 1), 0.5s opacity ease-out;
    background: #dcdcdc;
    border-radius: 8px;
}
.CardDesign.CardDesign--loaded .card-visual {
    transform: scale(1);
    opacity: 1;
}
.CardDesign:nth-child(8n+1) .card-visual { transition-delay: 0ms; }
.CardDesign:nth-child(8n+2) .card-visual { transition-delay: 80ms; }
.CardDesign:nth-child(8n+3) .card-visual { transition-delay: 160ms; }
.CardDesign:nth-child(8n+4) .card-visual { transition-delay: 240ms; }
.CardDesign:nth-child(8n+5) .card-visual { transition-delay: 320ms; }
.CardDesign:nth-child(8n+6) .card-visual { transition-delay: 400ms; }
.CardDesign:nth-child(8n+7) .card-visual { transition-delay: 480ms; }
.CardDesign:nth-child(8n+8) .card-visual { transition-delay: 560ms; }

.CardDesign:nth-child(2n+2) {
    margin-left: 7px;
}
button.CardDesign {
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    padding: 0px;
    border-radius: 8px;
}
button.CardDesign img {
    display: block;
    margin: 0px;
    overflow: hidden;
    width: 100%;
}
button.CardDesign:focus {
    outline: none;
    box-shadow: none;
}

.CardDesign > .tick {
    position: absolute;
    width: 36px;
    height: 36px;
    bottom: -6px;
    right: -6px;
    transform: scale(1, 1);
    transition: 0.3s transform cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.CardDesign > .tick--off {
    transform: scale(0.001, 0.001);
    transition: 0.3s transform cubic-bezier(0.6, -0.28, 0.735, 0.045);
}