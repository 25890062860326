.Page {
    padding: 0;
    margin: 0;
    text-align: center;
}

a, u {
    text-decoration: none;
}

.TitleLabel {
    font-family: 'Bariol-Bold';
    color: #333b44;
    text-align: center;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.47px;
}

.DescriptionLabel {
    color: #333b44;
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.61px;
}

.PlainInput {
    width: 100%;
    font-size: 17px;
    border-style: none;
    border-bottom-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}