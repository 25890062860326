.dot {
    height: 45px;
    width: 45px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.dotWhite {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border-color:  #bbb;
    border-bottom-style: solid;
    border-bottom-width: 1px;    
    box-shadow: 0 0 0 1px  #bbb;
}

.box {
    border-style: none;
    border-bottom-color: #ABB2B9;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 8px;
    text-align: left;
};


.boxRight {
    text-align: left;
}

.boxLeft {
    text-align: left;
}

