.FilledButton {
    appearance: none;
    border: 0px;
    background-color: transparent;
    border-radius: 8px;
}

button.FilledButton {
    display: block;
    width: 100%;
    padding: 0px;
}

.FilledButton > div {
    height: 48px;
    line-height: 48px;
    background-color: #1ea3ce;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-family: 'Bariol-Bold';
    font-size: 20px;
    letter-spacing: 0.42px;
}