.TextInput {

}

.TextInput > *{
    margin: 0;
    padding: 0;
}


.TextInputTitle {
    font-size: 12px;
    line-height: 24px;
    color: #909090;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.TextInputField {}

.TextInputField ::-webkit-input-placeholder {
    color: #909090;
}

.ErrorInput{
    color: red;
}