@mixin charity-buttons {
    font-size: 20px;
    border-radius: 8px;

}

.btn {
    height: 48px;
}

.btn.btn-positive {
    @include charity-buttons;
    background-color: rgb(28, 66, 112);
    color: rgb(255, 255, 255);

    &:active, &:focus, &:hover {
        color: rgb(255, 255, 255);
    }
}

.btn.btn-negative {
    @include charity-buttons;

    background-color: rgba(28, 66, 112, 0.15);
    color: rgb(28, 66, 112);
}

a:link {
    color: rgb(28, 66, 112)
}

a:visited {
    color: rgb(28, 66, 112)
}

.Title {
    /* fontWeight: "bold", marginTop: "24px" */
    font-weight: bold;
    margin-top: 16px;
}

.BottomLayout {
    position: absolute;
    bottom: 16px;
    width: 100%;
}

//  Confirm Donation Page
.conf_row {
    display: flex;
    text-align: left;
}
.conf_column {
    flex: 50%;
}
.conf_column b {
    font-size: 17px;
    float: left;
}
.conf_column p {
    font-size: 18px;
    float: left;
}