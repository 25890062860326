.text {
    text-align: left;
    font-weight: bold;
    font-size: 17px;
    line-height: 17px;
}

.blink {
    animation: blink-animation 1.2s steps(2, start) infinite;
    -webkit-animation: blink-animation 1.2s steps(2, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }