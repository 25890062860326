.InputSelect {
    position: relative;
    select {
        appearance: none;
        width: 100%;
        outline: none;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #000000;
        border-radius: 0px;
        height: 28px;
        line-height: 28px;
        padding: 0px;
        padding-top: 1px;
        margin: 0px;
        background: transparent;
        font-size: 17px;
        box-sizing: content-box;
    }
}