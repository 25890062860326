@font-face {
  font-family: 'Bariol';
  src: local('Bariol-Regular'), url('./assets/fonts/Bariol-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bariol-Bold';
  src: local('Bariol-Bold'), url('./assets/fonts/Bariol-Bold.ttf') format('truetype');
}

input { -webkit-user-select:text;}

* {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

.App {
  text-align: center;
}

#topFixer {
  width: 100%;
  margin: 0;
  padding-top: 1px
}

input[type="text"], input[type="email"], input[type="phone"], input[type="number"] {
  outline: none;
  box-shadow: none;
}

.tick-large {
  transform: scale(0.4, 0.4);
  opacity: 0;
  transition: 0.8s transform cubic-bezier(0, 1.3, 0.7, 1), 0.3s opacity ease-in;
}
.tick-large--on {
  opacity: 1;
  transform: scale(1, 1);
}