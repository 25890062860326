$wallet-color: #1ea3ce;
$savings-color: #7f3db3;
$card-color: #ff9e00;




.AccountListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 24px;
    .circle {
        margin-right: 12px;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border-style: solid;
        border-width: 3px;
        &.circle-wallet {
            border-color: #1ea3ce;
        }
        &.circle-savings {
            border-color: #7f3db3;
        }
        &.circle-card {
            border-color: #ff9e00;
        }
    }
    .circle-filled {
        border-style: hidden;
        &.circle-wallet {
            background-color: #1ea3ce;
        }
        &.circle-savings {
            background-color: #7f3db3;
        }
        &.circle-card {
            background-color: #ff9e00;
        }
    }
    .account-name {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 2px;
    }
    .balance-container {
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #88949f;
    }
}