$font-family-sans-serif: Bariol;
$blue: #1EA3CE;
$body-bg: #FAFAFD;
$input-bg: #F7F7F7;
$input-btn-border-width: 0px;
$btn-border-radius-lg: 8px;
$btn-font-size-lg: 20px;



@import "node_modules/bootstrap/scss/bootstrap";





body {
    height: 100%;
    font-family: Bariol !important;
    margin: 0px;
    background-color: #fafafd;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}

.btn {
    font-family: Bariol-Bold;
}
.btn.btn-link.btn-sm {
    font-size: 14px;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn.btn-secondary {
    color: rgb(30, 163, 206);
    background-color: rgba(30, 163, 206, 0.14902);
}

.MuiDialog-paperWidthSm {
    max-width: none !important;
}
.MuiDialog-paper {
    margin-left: 25px !important;
    margin-right: 25px !important;
}