.EnterManualAddress {
    &-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 25px;
        > * {
            width: 100%;
        }
        > * + * {
            margin-left: 16px;
        }
    }
}