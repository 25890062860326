
@import '../../index.scss';

.steps {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:21px;

    .circle {
        display: inline-block;
        margin: 5px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: rgba($blue, .15);

        &.active {
            width: 11px;
            height: 11px;
            background-color: $blue;
        }
    }
}
